body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  color: black !important;
  opacity: 1;
}

input {
  background-color: rgba(245,245,245,0.35) !important;
  border: none !important;
}

button {
  color: #000000 !important;
  background-color: #F7941D !important;
  border-color: #F7941D !important;
}

label {
  color: white !important;
  opacity: 1;
}

.paddingTop6px {
  padding-top: 6px;
}

.smallLabel {
  font-size: 0.75em !important;
  margin-bottom: 5px !important;
}

.bigPlaceholder {
  font-size: 1.25em !important;
}

#loadingSpinner {
  color: #F7941D !important;
}

#loadingSpinnerDiv {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: rgba(0,0,0, 0.7); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s;
}

#companyInfo {
 color: white !important;
}

#activationInfo {
  color: white !important;
  margin-left: -1px !important;
}

#linkText {
  color: white !important;
  text-decoration: none !important;
  /*text-decoration: none;*/
}

#linkText:hover {
  text-decoration: none !important;
  /*text-decoration: none;*/
}

#activationFailDiv {
  color: white;
  background-color: red;
  border-radius: 5px;
  padding: 5px;
}

body {
  background-image: url("./img/backgroundImage5.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #5c636a !important;
}

.successText {
  /*color: black !important;
  background-color: rgba(247,148,29,0.35) !important;*/
  color: white !important;
}
